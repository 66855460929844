// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

.c-sidebar.c-sidebar-light .c-sidebar-brand {
  color: #fff;
  background: white;
}

.c-sidebar-brand {
  display: flex;
  flex: 0 0 105px;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: red;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle {
  color: red;
  background: rgba(0, 0, 21, 0.05);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: grey;
}

.c-header .c-header-nav .c-header-nav-link:hover,
.c-header .c-header-nav .c-header-nav-link:focus,
.c-header .c-header-nav .c-header-nav-btn:hover,
.c-header .c-header-nav .c-header-nav-btn:focus {
  color: grey;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light
.c-active.c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: red;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show {
  background: white;
}

.c-sidebar-nav-link.c-active {
  background: red;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: red;
  background-color: #ebedef;
  border-color: #c4c9d0 #c4c9d0 #ebedef;
}

.nav-tabs .nav-link {
  /* border: 1px solid transparent; */
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link {
  /* border: 1px solid transparent; */
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: blue;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show {
  background: rgb(255, 255, 255);
}

.c-sidebar-nav-dropdown-items {
  color: red;
  max-height: 0;
  padding: 0;
  overflow-y: hidden;
  list-style: none;
  transition: max-height 0.3s ease-in-out;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
  color: blue;
  background: transparent;
}

.c-sidebar-nav-item .c-sidebar-nav-item.active {
  color: red;
}

.c-sidebar-nav-item .c-sidebar-nav-dropdown-toggle.active {
  color: red;
}

.c-sidebar-nav-link .c-active {
  color: red;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: blue;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
  color: white;
  background: blue;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-sidebar-nav-dropdown-toggle {
  color: blue;
  border-bottom: 3px solid white;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle {
  color: black;
  background: lightgray;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-sidebar-nav-dropdown-toggle {
  color: blue;

  border-bottom: 3px solid white;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-sidebar-nav-dropdown-toggle {
  color: black;
  /* background: lightgray; */
  border-bottom: 3px solid white;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
  color: white;
  background: cadetblue;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
  color: black;
  background: darkgray;
}

.alert-success {
  margin-top: 20px;
  color: #18603a;
  background-color: #d5f1de;
  border-color: #c4ebd1;
}

.modal-header {
  color: red;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
}

.alertimage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; // make sure the parent is full screen
  height: 100%; // so that the content will center correctly
}

.alertfooter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; // make sure the parent is full screen
  height: 100%; // so that the content will center correctly
}

.btnhover:hover {
  background: red;
  border-color: red;
}

.alertcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; // make sure the parent is full screen
  height: 100%;
  margin-top: "195px";
}

.buttonhov:hover {
  background: red;
  border-color: red;
}

.search {
  transition: 0.3s;
  margin-top: -28px;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  text-decoration: none;
  color: red;
  background-color: #ebedef;
}

.list-group-item-action {
  width: 100%;
  text-align: inherit;
  color: blue;
}

.settingmodal {
  margin-top: 200px;
}

.c-sidebar.c-sidebar-light .c-sidebar-brand {
  color: lightgray;
  background: white;
  border-bottom: inset;
}

.c-sidebar-nav-link:hover {
  color: red;
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-dropdown-toggle {
  background: lightgray;
}

.oldpersoncard {
  color: #f3f0d9;
}

.printHover:hover {
  color: red;
}

.printHover {
  color: blue;
}

.cardborder {
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: black;
}

.addHover:hover {
  color: limegreen;
}

.deleteHover:hover {
  color: red;
}

.filter:focus {
  outline: none;
  box-shadow: none;
}

.table-responsive {
  min-height: 500px;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.c-main {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding-top: 0;
}

// .c-sidebar.c-sidebar-light .c-sidebar-nav-link, .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle, .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
//     color: blue;
//     border-bottom: 2px solid lightgray;
//     background: transparent;
// }
.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle {
  color: red;
  background: lightgray;
}

table#beta {
  th,
  td {
    font-weight: normal;
    padding: 5px;
    width: 120px;
    vertical-align: top;
  }

  th {
    background: #e8e8e8;
    text-align: center;
  }

  tr + tr th,
  tbody th {
    background: #e8e8e8;
  }

  tr + tr,
  tbody {
    text-align: left;
  }

  table,
  th,
  td {
    border: solid 1px;
    border-collapse: collapse;
    table-layout: fixed;
    //background: #e8e8e8;
  }
}
